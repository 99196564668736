$.fn.lib_flickity = function () {
    let selector = $(this);
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function() {
            cssLoaded(function () {
                selector.each(function(){
                    let elm = $(this);
                    let options = elm.data("lib-flickity");
                    let nav = elm.parent().find("[data-lib-flickity-nav]");
                    let counter = elm.data("lib-flickity-counter");
                    let items = elm.children().length;

                    elm.on("ready.flickity", function(e){
                        elm.children(".flickity-button").lui_ripple();
                        if (elm.children(".flickity-button[disabled]").length === 2) {
                            elm.addClass("flickity-nav-disabled");
                        }
                    });

                    let slider = $(this).flickity({
                        draggable: (typeof options["draggable"] !== "undefined") ? options["draggable"] : true,
                        groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                        cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                        setGallerySize: true,
                        autoPlay: options["autoplay"],
                        pageDots: true,
                        wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                        contain: true,
                        adaptiveHeight: false,
                        pauseAutoPlayOnHover: true,
                        prevNextButtons: true,
                        percentPosition: false,
                        watchCSS: (typeof options["watchCSS"] !== "undefined") ? options["watchCSS"] : false,
                    });

                    let flkty = slider.data('flickity');

                    slider.on( 'select.flickity', function( event, i ) {
                        if (typeof counter !== "undefined") {
                            elm.attr("data-lib-flickity-counter", `${i+1} / ${items}`)
                        }
                    });

                    if (options["hasNested"] === true) {
                        slider.find("[data-lib-flickity]").each(function () {
                            let nested = $(this);
                            nested.on("pointerDown.flickity", function(e){
                                flkty.options.draggable = false;
                                flkty.updateDraggable();
                            });
                            nested.on("pointerUp.flickity", function(e){
                                flkty.options.draggable = true;
                                flkty.updateDraggable();
                            });
                        });
                    }

                    if (typeof options["flex"] !== "undefined") {
                        elm.addClass("flickity-flex")

                        win.on("resize",()=> {
                            elm.removeClass("flickity-flex");
                            slider.flickity('resize');
                            elm.addClass("flickity-flex");
                        });
                    }

                    if (typeof options["parallax"] !== "undefined") {
                        let carousel_img = slider.find('.part_ui_image'),
                            docStyle = document.documentElement.style,
                            transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                        slider.on('scroll.flickity', function () {
                            flkty.slides.forEach(function (slide, i) {
                                let img = carousel_img[i],
                                    x = (slide.target + flkty.x) * -1;

                                img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                            });
                        });
                    }

                    if (nav.length) {
                        nav.on("click", function(){
                            let direction = $(this).data("lib-flickity-nav");

                            if (direction === "prev") {
                                slider.flickity('previous');
                            } else {
                                slider.flickity('next');
                            }
                        });
                    }

                    elm.on("mousemove",function (e) {
                        let x = e.pageX - elm.get(0).offsetLeft;

                        if(x > elm.outerWidth()/2) {
                            elm.removeClass("is--left").addClass("is--right");
                        }
                        else {
                            elm.removeClass("is--right").addClass("is--left");
                        }
                    });
                    elm.on("mouseleave",function () {
                        elm.removeClass("is--left is--right");
                    })
                });
            });
        });
    }
};
